@import url(https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap);
.roboto {
  font-family: "Roboto", sans-serif;
}
:root {
  /* --themeyellow: #ecff00; */
  --themeyellow: #ff2b2b;
  --grayss: #9e9e9e;
  --aqua: #0ad2b9 !important;
  --whiteClr: #fff;
}
body {
  margin: 0;
  /* font-family: 'Orbitron', sans-serif; */
  /* font family changed A - B */
  font-family: "Roboto", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #050505 !important;
}

.tutorial input[type="file"]{
  display: block !important;
}
.previewimgset{
  width: 90px;
  height: 90px;
  object-fit: cover;
}
.browseinput{
  position: relative;
  z-index: 0;
}
.browseinput input{
  opacity: 0;
}
.browseinput  button{
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
.flexHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#plus_minus-button {
  margin-top: 30px;
}

#nav_links a {
  color: #000;
  text-decoration: none;
}

#nav_links a:hover {
  color: rgba(16, 153, 245, 0.836);
  text-decoration: none;
  font-weight: bold;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: auto !important;
}

header,
main,
footer {
  padding-left: 300px;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: auto !important;
}

@media screen and (min-width: 768px) {
}

@media only screen and (max-width: 992px) {
  header,
  main,
  footer {
    padding-left: 0;
  }
}

.navbar.bg-dark {
  background-color: #090909 !important;
}

.border-right {
  border-right: 1px solid #3d3d3d !important;
}
.asrt-td-loading {
  background-color: transparent !important;
  color: #fff !important;
}

.link_new_green {
  color: #02c076 !important;
}

.link_new_green:hover {
  color: #fff !important;
}
.react-datepicker-wrapper input {
  border: 1px solid #59615f !important;
  padding: 10px !important;
  width: 100% !important;
  display: block !important;
}
.react-datepicker-wrapper {
  width: 100% !important;
  display: block !important;
}
.react-datepicker-wrapper input:focus-visible {
  border: 1px solid #59615f !important;
  box-shadow: 0 0 0 0rem #fff !important;
  outline: -webkit-focus-ring-color auto 0px !important;
}
.link_green_add {
  color: #02c076 !important;
}
.link_green_add:hover {
  color: #fff !important;
}
.navbar_mobile_menu {
  position: fixed;
  right: 15px;
}

.navbar_mobile_menu .settings_head_menu {
  left: unset !important;
  right: 0px !important;
  position: absolute !important;
  z-index: 2;
  border: 1px solid #413f3f !important;
}

form .col-md-3 {
  display: flex;
  align-items: center;
}
form .col-md-3 label {
  margin-bottom: 0px !important;
}
form .col-md-9 .custom-file-upload {
  margin-bottom: 0px !important;
}
.border_blue_select .select__value-container {
  border-color: #59615f !important;
  /* min-height: 52px !important;
max-height: 100% !important;
height: unset !important; */
}

.container .form_cekc_show .rc-checkbox [type="checkbox"] {
  width: 15px !important;
  height: 15px !important;
  opacity: 1 !important;
  position: relative !important;
}
.cur_pointer_icon {
  cursor: pointer;
}
#update-contact .col-md-9 {
  color: #fff !important;
}
.no_underline {
  text-decoration: none !important;
}
.list-group-item.active,
.list-group-item[aria-current="page"],
.list-group-item:hover {
  border-color: transparent !important;
  color: var(--themeyellow) !important;
}
.sidebar_whole .sidebar-submenu a {
  background-color: #242424 !important;
}
.sidebar_whole .sidebar-submenu a:hover {
  background-color: var(--themeyellow) !important;
  color: var(--whiteClr) !important;
}
.thumb-vertical {
  background: var(--themeyellow) !important;
}
.select__value-container {
  padding-right: 40px !important;
}

.scroll_green_textarea::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}
.scroll_green_textarea::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.scroll_green_textarea::-webkit-scrollbar-thumb {
  background-color: #02c076;
}
.scroll_green_textarea {
  min-height: 80px !important;
}
.form-control:disabled,
.form-control[readonly],
.form-control {
  background-color: #e9ecef00 !important;
  color: #fff !important;
}
.cke_reset.cke_chrome {
  filter: invert(1);
}
.cursor {
  cursor: pointer !important;
}
.w-full {
  width: 100%;
}
.noshrink {
  flex-shrink: 0;
}
.track-vertical {
  width: 6px;
  background: #424242;
  height: 100%;
  top: 0px;
  right: 0px;
}
.sidebar_whole .list-group-item.active {
  z-index: unset !important;
}

html,
body {
  scrollbar-color: var(--themeyellow) #424242 !important;
  scrollbar-width: thin !important;
}
*::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #424242;
}
*::-webkit-scrollbar-thumb {
  background-color: #effb4d;
}

.flex_center .floatinglabel .right a.view_eyw_icon_color:hover {
  color: #ecff03 !important;
}

.roboto {
  font-family: "Roboto", sans-serif;
}

body {
  overflow-x: hidden;
  background: #050505 !important;
  /* font-family: 'Orbitron', sans-serif; */

  /* font family changed A - B */
  font-family: "Roboto", sans-serif !important;
}

html,
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  /* font-family: 'Orbitron', sans-serif; */
  /* font family changed A - B */
  font-family: "Roboto", sans-serif !important;
}

:root {
  /* --themeyellow: #ecff00; */
  --themeyellow: #ff2b2b;
  --grayss: #9e9e9e;
  --aqua: #0ad2b9 !important;
  --whiteClr: #fff;
}

.aqua {
  color: #0ad2b9 !important;
}

.yellow,
.yellowtxt {
  color: var(--themeyellow) !important;
}

.red {
  color: #f8658d !important;
}

.green {
  color: #03ff48 !important;
}

.gray {
  color: gray !important;
}

.themebtn.big {
  max-width: 170px;
  width: 100%;
}

.subhead {
  color: #757272;
  text-align: center;
  font-family: "Roboto", sans-serif !important;
}

.themecontainer {
  width: 95%;
}

.d-grid {
  display: grid;
}

.br-20 {
  border-radius: 20px !important;
}

.yelloweye .fa {
  background: var(--themeyellow);
  padding: 10px;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  color: #000;
}

/* .asrt-table-head .col-md-6:last-child {
    display: none;
  } */

#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -15rem;
  transition: margin 0.25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

.img-fluid.proofThumb {
  max-height: 135px;
  border-radius: 5px;
  display: block;
  margin: 15px 0 0;
}

#sidebar-wrapper .list-group {
  width: 15rem;
}

#page-content-wrapper {
  min-width: calc(100vw - 1px);
  padding-top: 65px;
  padding-bottom: 35px;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

#menu-toggle {
  color: #fff;
  border: 2px solid #3d3d3d;
  padding: 5px 7px;
  margin: 0 0px 0 0;
  line-height: 0;
  position: fixed;
  top: 16px;
  z-index: 9;
  left: 15px;
}

.navbar.adminNav {
  position: fixed;
  display: flex;
  align-items: center;
  top: 0px;
  width: 100%;
  padding: 10px 15px 10px 65px;
  z-index: 9;
}

.text-small {
  font-size: 0.9rem;
}

.chat-box {
  height: 510px;
  overflow-y: auto;
}

.rounded-lg {
  border-radius: 0.5rem;
}

input::placeholder {
  font-size: 0.9rem;
  color: #999;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem;
  }

  .adminNav .dropdown-menu a {
    display: block;
    color: #000;
    padding: 8px 10px;
  }
}

#as-react-datatable-container select {
  width: 70px !important;
}

/* .container input {
    position: static !important;
    opacity: 1 !important;
    cursor: pointer !important;
    width: 100% !important;
} */

.container [type="checkbox"] {
  position: absolute !important;
  opacity: 0 !important;
  cursor: pointer !important;
  height: 0 !important;
  width: 0 !important;
}

.chip-body {
  height: 37px !important;
}

.multiSelect {
  margin: 0 !important;
}

.tableHead h2 {
  font-size: 24px;
  color: #d6b032;
  text-align: left;
  margin: 12px 15px 15px;
  font-weight: 600;
}

.loadingContent {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: #000; */
  overflow: hidden;
}

.loading {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.loading div {
  position: absolute;
  background: #032eff;
  opacity: 1;
  border-radius: 50%;
  animation: loading 1.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.loading div:nth-child(2) {
  animation-delay: -0.7s;
}

@keyframes loading {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

.chat_content_center {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0px;
  border-bottom: 1px solid #e6e6e6;
}

.chat_content_center p {
  margin: 0;
  text-align: center;
}

.chat_content_center h4 {
  margin: 0;
  font-weight: 600;
  padding: 4px 0px;
  color: #0b3e8f;
}

.chat_conte_po h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 0px;
  color: #fff;
}

.chat_conte_po p {
  margin: 0;
  font-size: 13px;
  /* margin: 0; */
  font-weight: 600;
}

.ma-aut0pdd {
  margin: 11px auto;
}

.chat_conte_po a {
  color: #5d5e65 !important;
}

.chat_box_dark {
  min-height: 338px;
  background: #f6f8fe;
  border-radius: 5px;
  border: 1px solid #f6f8fe;
}

.footer_chat {
  width: 100%;
  padding: 25px;
}

.footer_chat .form-group {
  margin: 0;
}

.footer_chat .form-control {
  background: #e3e6f2;
  padding: 14px 11px;
  border-radius: 5px;
  border: 1px solid #e3e6f2;
  height: 45px;
  color: #666;
  text-transform: capitalize;
}

.button_section_po_chat {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}

.chat_message_section {
  min-height: 260px;
  height: 352px;
  overflow: auto;
}

.footer_chat .fa-paper-plane {
  float: right;
  margin-right: 20px;
  margin-top: -31px;
  font-size: 20px;
  color: #0b3e8f;
  cursor: pointer;
}

.footer_chat .fa-paperclip {
  float: left;
  margin-right: 60px;
  margin-top: -31px;
  font-size: 20px;
  color: #0b3e8f;
  cursor: pointer;
}

.message_section {
  padding: 22px 12px;
}

.message_section div {
  width: auto;
  padding: 6px 3px;
  position: relative;
  /* margin: 22px 9px; */
}

.message_section p {
  margin: 0;
  padding: 10px 30px;
  color: #666;
  background: #e3e6f2;
  font-size: 13px;
}

.message_section span {
  position: absolute;
  top: -16px;
  /* width: 100%; */
  right: 4px;
  color: #6f7075;
  font-size: 12px;
}

.message_send span {
  left: 4px !important;
}

.messAge_recive span {
  left: 4px !important;
}

.messAge_recive {
  padding: 22px 12px;
  display: flex;
  justify-content: flex-start;
}

.message_send {
  padding: 22px 12px;
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 767px) {
  .modal-content label {
    font-size: 12px;
  }

  .adminNav .dropdown-menu {
    background: transparent;
    margin-top: 0;
    padding-top: 0;
    border: 0;
  }

  .adminNav .dropdown-menu a {
    display: block;
    color: #fff;
    padding: 10px 0;
  }

  .adminNav .dropdown-menu a:hover {
    color: #d6b032;
    text-decoration: none;
  }

  .adminNav .dropdown-item:hover,
  .adminNav .dropdown-item:focus {
    background: transparent;
    text-decoration: none;
  }

  .adminNav.navbar-dark .navbar-toggler {
    color: #fff;
    border: 2px solid #3d3d3d;
    padding: 5px 7px;
    margin: 0 0px 0 0;
    line-height: 0;
    font-size: inherit;
    outline: none;
    top: 16px;
    z-index: 9;
  }

  .navbar.adminNav {
    position: fixed;
    display: flex;
    align-items: center;
    padding: 10px 15px 10px 65px;
    z-index: 9;
    width: 100%;
  }
}

.bg-dark {
  background-color: #090909 !important;
}

.css-2b097c-container {
  position: relative;
  box-sizing: border-box;
  z-index: 55555;
}

.card {
  background-color: #1a1b1c !important;
  border-radius: 0px !important;
}

/* input,.form-control,.container input
{
  background-color: transparent !important;
  border-color: #59615f !important;
  border-radius: 8px !important;
  color:#787272 !important;
  box-shadow: 0 0 0 0rem #fff !important;
} */
label {
  color: #d1d1d1 !important;
}

.page-item .page-link input {
  color: #fff;
  background-color: transparent;
}

.btn-primary {
  background: var(--themeyellow) !important;
  border: 1px solid var(--themeyellow) !important;
  color: var(--whiteClr) !important;
  text-transform: capitalize !important;
  font-weight: 600 !important;
  box-shadow: none !important;
  transition: 0.5s;
  font-size: 14px !important;
}

.flex_center a:hover {
  color: #fff !important;
}

.flex_center .floatinglabel .right a.view_eyw_icon_color:hover {
  color: #9da723 !important;
}

.flex_center a.view_eyw_icon_color:hover {
  color: #9da723 !important;
}

.grey-text {
  color: #ccc !important;
}

a::placeholder {
  color: #ccc !important;
}

.flex_center {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.flex_center .card h2 {
  font-size: 25px !important;
  margin-bottom: 20px !important;
}

.list-group-item {
  background-color: #1a1b1c !important;
  /* border-color:#3d3d3d !important; */
  /* border: 1px solid #3d3d3d !important; */
}

#wrapper .border-right,
.border-right {
  border-right: 1px solid #3d3d3d !important;
}

.list-group-item-action {
  color: #fff !important;
}

@media only screen and (max-width: 575px) {
  .input-group.asrt-page-length {
    justify-content: center !important;
  }

  .table-foot .col-md-6:first-child,
  .table-head .col-md-6:first-child {
    margin-bottom: 20px;
  }

  .input-group.asrt-page-length {
    justify-content: center !important;
  }

  /* .table-foot
    {
        margin-left: 0px !important;
        margin-right: 0px !important;
    } */
  .page-link {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .pagination.justify-content-end {
    justify-content: center !important;
  }

  .table-foot .col-md-6.text-right,
  .table-foot .col-md-6,
  .table-head .col-md-6.text-right,
  .table-head .col-md-6 {
    text-align: center !important;
  }

  .table-foot .col-md-6.text-right,
  .table-foot .col-md-6.text-right .pull-right,
  .table-head .col-md-6.text-right,
  .table-head .col-md-6.text-right .pull-right {
    float: unset !important;
  }

  .table_filter {
    margin-right: 0px !important;
    width: 100% !important;
  }

  .block_mob.d-flex {
    display: block !important;
    text-align: center;
  }

  .block_mob.d-flex a {
    display: block !important;
  }

  .flex_center .card {
    min-width: 290px !important;
  }
}

@media only screen and (min-width: 576px) {
  .flex_center .card {
    min-width: 350px !important;
  }
}

.navbar-brand img {
  max-width: 100px;
}

.text-secondary,
#page-content-wrapper .text-secondary {
  color: var(--themeyellow) !important;
}

th.sortable {
  color: #fff !important;
}

.table thead th,
.table,
.table thead td,
.table-bordered td {
  border-color: #3d3d3d !important;
  border-width: 1px !important;
  color: #fff !important;
}

.table-bordered td {
  color: #fff !important;
  font-size: 14px !important;
}

.btn-danger {
  background: #a81e3a !important;
  border: 1px solid #a81e3a !important;
  color: #fff !important;
  text-transform: capitalize !important;
  font-weight: 600 !important;
  box-shadow: none !important;
  transition: 0.5s;
  font-size: 14px !important;
}

.btn-danger:hover {
  background: transparent !important;
  border: 1px solid #a81e3a !important;
  color: #a81e3a !important;
}

th.sortable {
  filter: brightness(0) invert(1);
}

.sidebar_whole {
  position: fixed;
  z-index: 1;
  min-height: 100vh;
  background: #1a1b1c;
  /* overflow-y: scroll; */
  top: 63px;
  width: 250px !important;
}

@media only screen and (min-width: 992px) {
  #page-content-wrapper {
    min-width: 0;
    width: calc(100% - 250px);
    margin-top: 1%;
    margin-left: 250px;
  }

  .shrink_whole #page-content-wrapper {
    width: 100% !important;
    margin-left: 0px !important;
  }
}

.user_management_table td:first-child,
.user_management_table th:first-child,
.user_management_table td:nth-child(4),
.user_management_table th:nth-child(4),
.user_management_table td:nth-child(5),
.user_management_table th:nth-child(5),
.passbook_history_table td:first-child,
.passbook_history_table th:first-child {
  min-width: 150px !important;
}

.passbook_history_table td:nth-child(2),
.passbook_history_table th:nth-child(2) {
  min-width: 100px !important;
}

.passbook_history_table td:nth-child(7),
.passbook_history_table th:nth-child(7),
.passbook_history_table td:nth-child(8),
.passbook_history_table th:nth-child(8) {
  min-width: 150px !important;
}

.user_management_table td:nth-child(2),
.user_management_table th:nth-child(2) {
  min-width: 100px !important;
}

.user_management_table td:nth-child(6),
.user_management_table th:nth-child(6) {
  min-width: 170px !important;
}

.kyc_table th:nth-child(2),
.kyc_table td:nth-child(2),
.kyc_table th:last-child,
.kyc_table td:last-child {
  min-width: 150px !important;
}

.table-foot {
  color: #fff !important;
}

.page-item .page-link {
  background-color: transparent !important;
  color: #fff !important;
  border-color: #3d3d3d !important;
}

.page-item.disabled .page-link {
  background-color: transparent !important;
  color: #3d3d3d !important;
  border-color: #3d3d3d !important;
}

.modal-header,
.modal-footer {
  border-color: #3d3d3d !important;
}

.modal.show {
  padding-right: 0px !important;
}

.table_filter {
  max-width: 100% !important;
}

.page-item .page-link a {
  color: #fff !important;
}

.page-item .page-link input {
  height: unset !important;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .input-group.asrt-page-length {
    justify-content: center !important;
  }

  .table_filter {
    margin-right: 0px !important;
  }

  .table-foot .col-md-6:first-child,
  .table-head .col-md-6:first-child {
    margin-bottom: 20px;
  }

  /* .table-foot
    {
        margin-left: 0px !important;
        margin-right: 0px !important;
    } */
  .page-link {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .pagination.justify-content-end {
    justify-content: center !important;
  }

  .table-foot .col-md-6.text-right,
  .table-foot .col-md-6,
  .table-head .col-md-6.text-right,
  .table-head .col-md-6 {
    text-align: right !important;
  }

  .table-foot .col-md-6.text-right,
  .table-foot .col-md-6.text-right .pull-right,
  .table-head .col-md-6.text-right,
  .table-head .col-md-6.text-right .pull-right {
    float: unset !important;
  }
}

.modal-content {
  background-color: #1a1b1c !important;
  border-radius: 0px !important;
}

.modal-title,
.close {
  color: #fff !important;
}

.asrt-page-length .input-group-addon {
  background-color: transparent !important;
}

#as-react-datatable-container select {
  height: unset !important;
  color: #787272 !important;
}

.input-group.asrt-page-length {
  align-items: center;
}

.input-group.asrt-page-length .input-group-text {
  color: #ccc !important;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modal .page-link {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}

.btn {
  min-width: 32px !important;
}

.user_asset_modal_table .table-body .col-md-12 {
  display: block;
  width: 100%;
  overflow-x: auto;
}

.btn-info {
  background: transparent !important;
  border: 1px solid #242827 !important;
  color: #fff !important;
  text-transform: capitalize !important;
  font-weight: 600 !important;
  box-shadow: none !important;
  transition: 0.5s;
  font-size: 14px !important;
}

.btn-info:hover {
  background: #242827 !important;
  color: #fff !important;
}

.popUpSpace {
  color: #fff !important;
}

.word_brak {
  word-break: break-all;
}

.file_a {
  color: var(--themeyellow) !important;
}

.file_a .fa {
  margin-right: 5px !important;
}

.file_a:hover {
  color: #fff !important;
}

.support_table th:nth-child(3),
.support_table td:nth-child(3),
.support_table th:nth-child(2),
.support_table td:nth-child(2),
.support_table th:nth-child(5),
.support_table td:nth-child(5) {
  min-width: 150px !important;
}

.support_table th:first-child,
.support_table td:first-child {
  min-width: 100px;
}

.chat-box.bg-white {
  background-color: #1a1b1c !important;
}

.chat-box.bg-white .bg-light {
  background-color: #000 !important;
  color: #fff !important;
}

.bg-input-dark {
  background-color: #000 !important;
  color: #787272 !important;
  height: unset !important;
  resize: none !important;
}

.bg-light-dark {
  background-color: #000 !important;
  color: #787272 !important;
  height: unset !important;
  resize: none !important;
}

.input-grp-dark .btn-link {
  background-color: #585b5e !important;
  color: #fff !important;
  border-radius: 0px !important;
}

/* .input-grp-dark 
{
    border-left: 1px solid #3c3d3d !important;

} */
.input-grp-dark .btn-link:hover,
.ticketDetails .fa:hover {
  color: #02c076 !important;
}
.qrimg{
  width: 150px;
  height: 150px !important;
}
.ticketDetails .fa {
  color: #fff !important;
  font-size: 16px !important;
  margin-left: 10px !important;
}

.table-body .col-md-12 {
  display: block;
  width: 100%;
  overflow-x: auto;
}

.table-foot {
  margin-top: 10px;
}

#page-content-wrapper .mt-2.text-secondary {
  margin-bottom: 30px !important;
}

.btn-outline-primary,
.btn-secondary {
  background: transparent !important;
  border: 1px solid #242827 !important;
  color: #fff !important;
  text-transform: capitalize !important;
  font-weight: 600 !important;
  box-shadow: none !important;
  transition: 0.5s;
  font-size: 14px !important;
}

.btn-outline-primary:hover,
.btn-secondary:hover {
  background: #242827 !important;
  border: 1px solid #242827 !important;
  color: #fff !important;
}

.custom-select {
  background-color: #1a1b1c !important;
  /* background: #1a1b1c !important; */

  border-color: #59615f !important;
  border-radius: 8px !important;
  color: #787272 !important;
  box-shadow: 0 0 0 0rem #fff !important;
  /* height: 52px !important; */
}

.custom-select option {
  background-color: #1a1b1c !important;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  background-color: transparent !important;
  border: 1px solid #ced4da;
  border-color: #59615f !important;
  border-radius: 8px !important;
  color: #787272 !important;
  box-shadow: 0 0 0 0rem #fff !important;
  height: 52px !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 12px;
  cursor: pointer;
}

.table_filter {
  margin-right: 0px !important;
}

/* .cke_inner,.cke_top,.cke_chrome
{
    background: #1a1b1c !important;
    border-color:#242827 !important;
}
.cke_wysiwyg_frame, .cke_wysiwyg_div,.cke_wysiwyg_frame body
{
    background-color: #1a1b1c !important;
    background: #1a1b1c !important;

}
.cke_top {
    border-bottom: 1px solid #242827 !important;
}
a.cke_button_off:hover, a.cke_button_off:focus, a.cke_button_off:active,
.cke_combo_on a.cke_combo_button, .cke_combo_off a.cke_combo_button:hover, .cke_combo_off a.cke_combo_button:focus, .cke_combo_off a.cke_combo_button:active
{
    background: #000 !important;
    border: 1px #242827 solid !important;
}
.cke_toolbar_separator
{
    background-color: #242827 !important;
}
.cke_toolgroup a.cke_button:last-child:after, .cke_toolgroup a.cke_button.cke_button_disabled:hover:last-child:after,
.cke_combo:after
{

    border-color:#242827 !important;
} */
.email_table th:nth-child(2),
.email_table td:nth-child(2) {
  min-width: 150px !important;
}

/* form .row
{
    align-items: center;
} */
/* form .row label
{
    margin-bottom: 0px !important;
} */
.dropdown-menu {
  background: #1a1b1c !important;
  border-radius: 0px !important;
}

.dropdown-menu a {
  color: #fff !important;
}

.dropdown-menu a:hover {
  font-weight: unset !important;
  color: var(--themeyellow) !important;
}

.login_history_table td:last-child,
.login_history_table th:last-child,
.login_history_table td:nth-child(8),
.login_history_table th:nth-child(8),
.login_history_table td:nth-child(6),
.login_history_table th:nth-child(6) {
  min-width: 150px !important;
}

.login_history_table td:first-child,
.login_history_table th:first-child,
.login_history_table td:nth-child(3),
.login_history_table th:nth-child(3),
.login_history_table td:nth-child(2),
.login_history_table th:nth-child(2) {
  min-width: 100px !important;
}

.basic-multi-select {
  z-index: 1 !important;
}

/* .basic-multi-select>div
{
    z-index: 99999 !important;
} */
/* .mySelect__value-container{
    height: 35px !important;
   } */
.select__control,
.select__indicators,
.select__menu {
  /* background-color: #000 !important;
    background: #000 !important;
    border-color: #242827 !important; */
  box-shadow: 0 0 0 0 #fff !important;
}

.input_grp_width input {
  width: 1% !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.input_grp_width .input-group-text {
  background-color: transparent !important;
  border-color: #59615f !important;
  color: #fff !important;
}

.input_grp_width .input-group-text i {
  color: #fff !important;
}

.input_grp_width .input-group-text {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.input_grp_width .input-group-text i:hover {
  color: var(--themeyellow) !important;
}

/* .select__multi-value
{
    background-color: #1a1b1c !important;
    background: #1a1b1c !important;
    color:#fff !important; 
}
.select__multi-value__label
{
    color:#fff !important; 

} */
/* .select__menu div:hover
{
    background-color: #1a1b1c !important;
    background: #1a1b1c !important;
    color:#fff !important; 
} */
.settings_head_menu a {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.select__menu {
  background: #000 !important;
}

.btn-info {
  width: auto !important;
}

.floatinglabel {
  position: relative;
}

.floatinglabel label {
  /* position: absolute; */
  left: 16px;
  top: -6px;
  font-size: 12px;
  color: #fff;
  z-index: 1;
}

.floatinglabel label::after {
  content: "";
  position: absolute;
  background: #000;
  height: 1px;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: -1;
}

.floatinglabel label::after {
  background: unset !important;
}

.floatinglabel .form-control,
.custom-file-upload {
  background: transparent;
  border-color: #636363;
  border-radius: 10px;
  height: 46px !important;
  color: #c7c7c7 !important;
  appearance: auto !important;
  -moz-appearance: auto;
  -webkit-appearance: auto;
  font-size: 12px !important;
}

.floatinglabel .right {
  position: absolute;
  right: 10px;
  top: 44px;
  color: #acacac;
}

.form-check,
label {
  /* font-family: 'Orbitron', sans-serif; */
  /* font family changed A - B */
  font-family: "Roboto", sans-serif !important;
}

/* .btn-light
  {
      background: #02c076 !important;
      border: 1px solid #02c076 !important;
      color: #fff !important;
      text-transform: capitalize !important;
      font-weight: 600 !important;
      box-shadow: none!important;
      transition: .5s;
      font-size: 14px !important;
  }
  .btn-light:hover
  {
      background-color: transparent !important;
      border: 1px solid #02c076 !important;
      color: #02c076 !important;
  
  } */
.themebtn,
.btn.btn-light {
  background-color: var(--themeyellow);
  border: 1px solid var(--themeyellow);
  border-radius: 10px;
  padding: 10px 30px !important;
  color: var(--whiteClr);
  font-size: 12px;
  font-weight: 700;
  box-shadow: 0 0 5px 5px #222121;
}

.themebtn:hover,
.btn.btn-light:hover {
  border: 1px solid var(--themeyellow);
  background-color: transparent !important;
  color: var(--themeyellow) !important;
}

.form-control:focus {
  background-color: transparent !important;
  box-shadow: none !important;
  border-color: #888889 !important;
}

.table_filter .form-control {
  border-color: #dee2e65e !important;
}

.flexing_disable_btn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
}

.regist_h2 {
  color: var(--themeyellow) !important;
}

.a_links {
  color: var(--themeyellow) !important;
}

/* karthick css */
.input__borderColor.form-control {
  border-color: #5d5d5d !important;
}

.addingPair__cmnLabel {
  font-size: 13px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

.derivative__modalBody {
  max-height: 55vh;
  overflow-y: auto;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
.editProfile__dropBtn{
  border: 1px solid var(--whiteClr) !important;
  outline: none;
  background-color: transparent;
  color: var(--whiteClr) !important;
  font-size: 13px !important;
  font-weight: 500;
  text-align: start !important;
  box-shadow: none !important;
  padding: 8px 15px !important;
}
.editProfile__dropBtn::after{
  display: none !important;
}
.editProfile__dropItem{
  color: var(--whiteClr) !important;
  font-size: 13px !important;
  font-weight: 500 !important;
}
.editProfile__dropItem:hover,.editProfile__dropItem:focus,.editProfile__dropItem:focus-visible,.editProfile__dropItem:target,.editProfile__dropItem:active{
  color: #000 !important;
  background-color: var(--whiteClr) !important;
}

/* switch css */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
    width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

.checkm .form-check-input{
   position: unset !important;
   margin-top: 0 !important;
   margin-left: 0 !important;
}
 .checkm .form-check-input:checked {
  background-color: #E21818 !important;;
    border-color: #E21818 !important;
  }

input:checked + .slider {
  background-color: var(--themeyellow);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--themeyellow);
}

input:checked + .slider:before {
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
/* end of switch css */
/* end of karthick css */
